<template>
  <div id="parkingSpaceTab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="
                buttonList.includes('parkingLotList-detail-parkingSpaceTab-add')
              "
              ><i class="iconfont icon-jiahao"></i> 新建
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="seatGroupName" label="分组名称" align="center">
        </el-table-column>
        <el-table-column
          prop="chargeAdmission"
          label="满位收费入场"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="quantityLimit"
          label="最大车位数量"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="attributableParty" label="所属方" align="center">
        </el-table-column>
        <el-table-column prop="contacts" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactPhone" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-parkingSpaceTab-edit'
                )
              "
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="del(scope.row.seatGroupId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-parkingSpaceTab-delete'
                )
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, prev, pager, next,jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @size-change="getList(1)"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog
      ref="actionDialog"
      :getList="getList"
      @refreshList="getList(1)"
    />
  </div>
</template>
<script>
export default {
  components: {
    actionDialog: () => import("./actionDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    edit(row) {
      this.$refs.actionDialog.seatGroupId = row.seatGroupId;
      this.$refs.actionDialog.attributableParty = row.companyId
        ? "公司"
        : row.owner
        ? "个人"
        : "";
      this.$refs.actionDialog.actionForm = {
        seatGroupName: row.seatGroupName,
        quantityLimit: row.quantityLimit,
        chargeAdmission: row.chargeAdmission === "是",
        owner: row.owner,
        companyId: row.companyId,
        contacts: row.contacts,
        contactPhone: row.contactPhone,
        remarks: row.remarks,
      };
      this.$refs.actionDialog.show = true;
    },
    del(seatGroupId) {
      this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.post(
            `/parking/seat/group/${seatGroupId}/delete`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/seat/group/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.attributableParty = item.companyName ?? item.owner;
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#parkingSpaceTab {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);

          span {
            color: hsla(217, 48%, 15%, 0.96);
            margin-right: 4px;
          }
        }

        li {
          .addVehicle {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
